<template>
    <div class="tip_mask" v-if="visible" @click="hideMask">
      <div class="tip_mask_content">
        <img src="../assets/arrow_topright.png" alt="">
        <div class="tip_mask_text">
          {{ tip }}
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: "TipMask",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: '请选择在浏览器中打开'
    }
  },
  methods: {
    hideMask() {
      this.$emit('toggle')
    }
  }
};
</script>
<style scoped>
.tip_mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(38, 38, 42, 0.8);
  z-index: 9999;
}
.tip_mask_content {
  position: absolute;
  top: 5px;
  right: 18px;
  text-align: right;
}
.tip_mask img {
  width: 42px;
  height: 69px;
  float: right;
}
.tip_mask .tip_mask_text {
  display: inline-block;
  color: #FFFFFF;
  font-size: 12px;
  padding: 8px 12px;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  transform: translate(2.5px, calc(69px - 50%));
}
</style>